import React from "react";
import "./Products.css";
import { Nawigacja } from "../../containers";
import { AllRights } from "../../components";
import { Link } from "react-router-dom";
import { SlSizeFullscreen } from "react-icons/sl";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { GiWoodBeam, GiWoodenFence } from "react-icons/gi";
import { PiPaintBrushBroadBold } from "react-icons/pi";
import { BsDot } from "react-icons/bs";

const Products = ({
  path,
  title,
  kategoria,
  img,
  text,
  szerokosc,
  wysokosc,
  cena
}) => {
  return (
    <>
      <div className="products__kontener">
        <Nawigacja />
        <div className="products__kontener-karta_produktu padding__szerokosc-sekcji padding__wysokosc-sekcji">
          <div className="products__kontener-header">
            <div className="products__left">
              <div className="nawigacja__gorna">
                <p>
                  <span>produkty</span>
                  <span>-</span>
                  <span>{kategoria}</span>
                </p>
                <button>
                  <Link to="/" className="nawigacja__gorna-button">
                    {"<< Powrót"}
                  </Link>
                </button>
                <div className="products__left-title">
                  <h4>
                    {(kategoria === "altanki" && "Altanka") ||
                      (kategoria === "domki" && "Domek") ||
                      (kategoria === "drewutnie" && "Dretutnia")}
                  </h4>
                  <h2>{title}</h2>
                </div>
                <div className="products__opis">
                  <p>{text}</p>
                  <p>{text}</p>
                </div>
                <div className="products__szczegoly">
                  <div className="products__szczegoly-kontener">
                    <p>
                      <span>
                        <SlSizeFullscreen />
                      </span>
                      Wymiary
                    </p>
                    <div className="products__opcje">
                      <p>
                        <span>
                          <AiOutlineColumnWidth />
                        </span>{" "}
                        {szerokosc}{" "}
                      </p>
                      <p>
                        <span>
                          <AiOutlineColumnWidth />
                        </span>{" "}
                        {wysokosc}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="products__szczegoly-kontener">
                    <p>
                      <span>
                        <GiWoodBeam />
                      </span>
                      materiał
                    </p>
                    <div className="products__opcje">
                      <p>
                        <span>
                          <GiWoodenFence />
                        </span>
                        drewno sosnowe
                      </p>
                    </div>
                  </div>
                  <div className="products__szczegoly-kontener">
                    <p>
                      <span>
                        <PiPaintBrushBroadBold />
                      </span>
                      kolor
                    </p>
                    <div className="products__opcje">
                      <p>
                        <span>
                          <BsDot />
                        </span>
                        skandynawska biel
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products__buttons">
                  <div className="products__cena">
                    <p>{cena}</p>
                  </div>
                  <div className="products__zamow">
                    <button>Zamów</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="products__right">
              <img src={img} alt={title} />
            </div>
          </div>
        </div>
      </div>
      <AllRights />
    </>
  );
};

export default Products;
