import React from "react";
import "./CounterModul.css";

const CounterModul = ({ img, title, counter }) => {
  return (
    <div className="counter__modul">
      <div className="counter__modul-img">
        <img src={img} alt={title} />
      </div>
      <div className="counter__modul-opis">
        <div className="counter__modul-opis_title">
          <h5>{title}</h5>
        </div>
        <div className="counter__modul-opis_licznik">
          <p>{counter}</p>
        </div>
      </div>
    </div>
  );
};

export default CounterModul;
