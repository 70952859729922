import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

const r = document.querySelector(":root");
const a = getComputedStyle(document.documentElement).getPropertyValue(
  "--mainBackground"
);

const Menu = () => {
  return (
    <div className="menu__kontener">
      <p>
        {/* <a
          href="#"
          onClick={() => {
            document.documentElement.style.setProperty("--secondText", "red");
          }}>
          Strona Główna
        </a> */}
        <Link to="/">
          <a>Strona Główna</a>
        </Link>
      </p>
      <p>
        <a
          href="#"
          onClick={() => {
            console.log(a);
          }}>
          Oferta
        </a>
      </p>
      <p>
        <a href="#">Realizacje</a>
      </p>
      <p>
        <a href="#">O Nas</a>
      </p>
      <p>
        <a href="#">Kontakt</a>
      </p>
    </div>
  );
};

export default Menu;
