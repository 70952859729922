import React, { useState } from "react";
import "./OfertaPowitanie.css";
import { CounterModul, OfertaKwadrat } from "../../../components";
import {
  Prezentacja01,
  Prezentacja02,
  offerBox01,
  offerBox02,
  offerBox03,
  offerBox04,
  Offer01,
  Offer02,
  Offer03
} from "../../../assets";
import { BsCheck } from "react-icons/bs";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const counterData = [
  {
    img: offerBox01,
    title: "Wykonanych Altanek",
    counter: "359"
  },
  {
    img: offerBox02,
    title: "Wykonanych Domków",
    counter: "74"
  },
  {
    img: offerBox03,
    title: "Wykonanych Drewutni",
    counter: "159"
  },
  {
    img: offerBox04,
    title: "Zadowolonych Klientów",
    counter: "592"
  }
];

const offerBoxData = [
  {
    img: offerBox01,
    title: "Altanki",
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus eius quasi veniam quod blanditiis repellat laboriosam aperiam aspernatur`,
    imgOferta: Offer01
  },
  {
    img: offerBox02,
    title: "Domki Letniskowe",
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus eius quasi veniam quod blanditiis repellat laboriosam aperiam aspernatur`,
    imgOferta: Offer02
  },
  {
    img: offerBox03,
    title: "Drewutnie",
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus eius quasi veniam quod blanditiis repellat laboriosam aperiam aspernatur`,
    imgOferta: Offer03
  }
];

const OfertaPowitanie = () => {
  const [offerDataIndex, setofferDataIndex] = useState(0);
  return (
    <div className="ofertapowitanie__kontener">
      <div className="ofertakwadrat__modul-kontener padding__szerokosc-sekcji">
        {offerBoxData.map((data, index) => {
          return (
            <OfertaKwadrat
              img={data.img}
              title={data.title}
              text={data.text}
              key={index + data.title}
            />
          );
        })}
      </div>
      <div className="ofertapowitanie__prezentacjaFirmy padding__szerokosc-sekcji padding__wysokosc-sekcji">
        <div className="prezentacjaFirmy__tytul-sekcji">
          <h4>DOBRZE, ŻE JESTEŚ spełnimy twoje ogrodowe marzenia</h4>
          <h1>Altanka z duszą - Twój osobisty azyl w ogrodzie</h1>
          <div className="prezentacjaFirmy__opis">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Doloremque ipsa aperiam harum nam laboriosam sed consequatur
              incidunt consequuntur eaque totam, odit numquam consectetur minus
              asperiores neque earum obcaecati, quisquam voluptatum.
            </p>
          </div>
          <div className="prezentacjaFirmy__opis-opcje">
            <p>
              <span>
                <BsCheck />
              </span>
              Precyzja i dokładność
            </p>
            <p>
              <span>
                <BsCheck />
              </span>
              Kreatywność i zdolności projektowania
            </p>
            <p>
              <span>
                <BsCheck />
              </span>
              Wiedza z zakresu wzornictwa i stylów
            </p>
            <p>
              <span>
                <BsCheck />
              </span>
              Staranność
            </p>
            <p>
              <span>
                <BsCheck />
              </span>
              Pasja i zaangażowanie
            </p>
          </div>
        </div>
        <div className="prezentacjaFirmy__img-sekcja">
          <div className="prezentacjaFirmy__img-first">
            <img src={Prezentacja01} alt="" />
          </div>
          <div className="prezentacjaFirmy__img-second">
            <img src={Prezentacja02} alt="" />
          </div>
        </div>
      </div>
      <div className="oferta__kontener padding__szerokosc-sekcji padding__wysokosc-sekcji">
        <div className="oferta__img-sekcja">
          <img src={offerBoxData[offerDataIndex].imgOferta} alt="" />
        </div>
        <div className="oferta__opis-sekcja">
          <div className="oferta__opis-text">
            <h2 id="oferta__opis-text-h2">
              {offerBoxData[offerDataIndex].title}
            </h2>
            <div className="oferta__opis-paragraph">
              <p id="oferta__opis-text-p">
                {offerBoxData[offerDataIndex].text}
              </p>
              <p id="oferta__opis-text-p2">
                {offerBoxData[offerDataIndex].text}
              </p>
            </div>
          </div>

          <div className="oferta__nawigacja-kontener">
            <button
              onClick={() => {
                if (offerDataIndex <= 0) {
                  setofferDataIndex(0);
                } else {
                  setofferDataIndex(offerDataIndex - 1);
                }
                const p = document.getElementById("oferta__opis-text-p");
                const p2 = document.getElementById("oferta__opis-text-p2");
                const h2 = document.getElementById("oferta__opis-text-h2");
                h2.classList.add("oferta__animacja-h2");
                p.classList.add("oferta__animacja-p");
                p2.classList.add("oferta__animacja-p");
                setTimeout(() => {
                  h2.classList.remove("oferta__animacja-h2");
                  p.classList.remove("oferta__animacja-p");
                  p2.classList.remove("oferta__animacja-p");
                }, 1200);
              }}>
              <MdOutlineArrowBackIosNew />
            </button>
            <button
              onClick={() => {
                if (offerDataIndex >= offerBoxData.length - 1) {
                  setofferDataIndex(offerBoxData.length - 1);
                } else {
                  setofferDataIndex(offerDataIndex + 1);
                }
                const h2 = document.getElementById("oferta__opis-text-h2");
                const p = document.getElementById("oferta__opis-text-p");
                const p2 = document.getElementById("oferta__opis-text-p2");
                h2.classList.add("oferta__animacja-h2");
                p.classList.add("oferta__animacja-p");
                p2.classList.add("oferta__animacja-p");
                setTimeout(() => {
                  h2.classList.remove("oferta__animacja-h2");
                  p.classList.remove("oferta__animacja-p");
                  p2.classList.remove("oferta__animacja-p");
                }, 1200);
              }}>
              <MdOutlineArrowBackIosNew />
            </button>
          </div>
        </div>
      </div>
      <div className="couter__kontener">
        <div className="counter__zawartosc padding__szerokosc-sekcji padding__wysokosc-sekcji">
          <div className="tytul__sekcji">
            <h4>z nami masz gwarancję spełnienia marzeń</h4>
            <h2>Miara Naszych Sukcesów</h2>
          </div>
          <div className="counter__konener-modulu">
            {counterData.map((data, index) => {
              return (
                <CounterModul
                  img={data.img}
                  title={data.title}
                  counter={data.counter}
                  key={index + data.title}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfertaPowitanie;
