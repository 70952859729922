import React from "react";
import "./Kontakt.css";
const Kontakt = () => {
  return (
    <div className="kontakt__kontener padding__wysokosc-sekcji padding__szerokosc-sekcji">
      <div className="kontakt__tytul tytul__sekcji">
        <h2>Kontakt</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ullam
          recusandae incidunt beatae commodi fuga modi quos, numquam sapiente.
        </p>
      </div>
      <div className="kontakt__formularz">
        <div className="input__kontener">
          <input type="text" placeholder="Imię i nazwisko" />
        </div>
        <div className="input__kontener">
          <input type="text" placeholder="E-mail" />
        </div>
        <div className="input__kontener">
          <input type="text" placeholder="Telefon" />
        </div>
        <div className="kontakt__button">
          <button>wyślij</button>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;


