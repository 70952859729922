import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Home, Products } from "./pages";
import { altankiData, domkiData, drewutnieData } from "./libs";

const kategoria = [
  { kategoria: "altanki" },
  { kategoria: "domki" },
  { kategoria: "drewutnie" }
];

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Home />} />

        <Route path={`/produkty/`}>
          {kategoria.map(kategoria => {
            if (kategoria.kategoria === "altanki") {
              return altankiData.map((data, index) => {
                return (
                  <Route
                    path={`${kategoria.kategoria}/${data.id}`}
                    element={
                      <Products
                        title={data.title}
                        kategoria={data.kategoria}
                        id={data.id}
                        img={data.img}
                        text={data.text}
                        cena={data.cena}
                        wysokosc={data.wysokosc}
                        szerokosc={data.szerokosc}
                        path={kategoria.kategoria}
                      />
                    }
                  />
                );
              });
            } else if (kategoria.kategoria === "domki") {
              return domkiData.map((data, index) => {
                return (
                  <Route
                    path={`${kategoria.kategoria}/${data.id}`}
                    element={
                      <Products
                        title={data.title}
                        kategoria={data.kategoria}
                        id={data.id}
                        img={data.img}
                        text={data.text}
                        cena={data.cena}
                        wysokosc={data.wysokosc}
                        szerokosc={data.szerokosc}
                        path={kategoria.kategoria}
                      />
                    }
                  />
                );
              });
            } else if (kategoria.kategoria === "drewutnie") {
              return drewutnieData.map((data, index) => {
                return (
                  <Route
                    path={`${kategoria.kategoria}/${data.id}`}
                    element={
                      <Products
                        title={data.title}
                        kategoria={data.kategoria}
                        id={data.id}
                        img={data.img}
                        text={data.text}
                        cena={data.cena}
                        wysokosc={data.wysokosc}
                        szerokosc={data.szerokosc}
                        path={kategoria.kategoria}
                      />
                    }
                  />
                );
              });
            }
          })}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
