import React from "react";
import "./OfertaPrzedstawienie.css";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import {
  OfertaAltanki,
  OfertaDomki,
  OfertaDrewutnie
} from "../../../containers";

const OfertaPrzedstawienie = () => {
  const location = useLocation();

  return (
    <div className="ofertaPrzedstawienie__kontener padding__szerokosc-sekcji padding__wysokosc-sekcji">
      <div className="ofertaPrzedstawienie__tytul tytul__sekcji">
        <h4>z naszych wzorów wybierzesz coś odpowiedniego dla siebie</h4>
        <h2>Nasza Oferta</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
          obcaecati odit corporis, assumenda blanditiis doloremque repudiandae
          libero optio eum ullam, fugiat nobis cupiditate cum accusamus
          temporibus! Officia explicabo esse voluptas!
        </p>
      </div>
      <div className="ofertaPrzedstawienie__kategorie-nawigacja">
        <NavLink
          to={"/altanki"}
          className={`${
            location.pathname === "/" ? "przycisk" : "przyciskOff"
          }`}>
          <button>Altanki</button>
        </NavLink>
        <NavLink className="przyciskOff" to="/domki">
          <button>Domki letniskowe</button>
        </NavLink>
        <NavLink className="przyciskOff" to="drewutnie">
          <button>drewutnie</button>
        </NavLink>
      </div>
      <div className="ofertaPrzedstawienie__lista">
        <Routes>
          {["/", "/altanki"].map((path, index) => {
            return (
              <Route
                path={path}
                element={<OfertaAltanki key={index + path} />}
              />
            );
          })}
          <Route path="domki" element={<OfertaDomki />} />
          <Route path="drewutnie" element={<OfertaDrewutnie />} />
        </Routes>
      </div>
    </div>
  );
};

export default OfertaPrzedstawienie;
