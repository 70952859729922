import React from "react";
import "./Header.css";
import { Nawigacja } from "../../containers";
import { Header01, Header02 } from "../../assets";

const Header = () => {
  return (
    <div
      className="header__kontener"
      style={{ backgroundImage: `url(${Header01})` }}>
      <div className="header__nawigacja">
        <Nawigacja />
      </div>
      <div className="header__slideText padding__szerokosc-sekcji">
        <div className="slideTitle">
          <h2>
            Czy marzysz, aby Twój ogród stał się{" "}
            <span>magicznym i przytulnym miejscem?</span>
          </h2>
        </div>
        <div className="slideParagraph">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus
            cupiditate dolores dolorem beatae quidem at blanditiis magnam odit
            quo tempora quas non, illum expedita, suscipit rem consequatur
            corporis sed officia.
          </p>
        </div>
        <div className="slideButtons">
          <button>Oferta</button>
          <button>Realizacje</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
