import React from "react";
import "./Oferta.css";
import { OfertaPowitanie, OfertaPrzedstawienie } from "../../containers";

const Oferta = () => {
  return (
    <div>
      <OfertaPowitanie />
      <OfertaPrzedstawienie />
    </div>
  );
};

export default Oferta;
