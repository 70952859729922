import React from "react";
import "./Ofirmie.css";
import { About01 } from "../../assets";

const Ofirmie = () => {
  return (
    <div className="ofirmie__kontener ">
      <div className="ofirmie__kontener-zawartosc ">
        <div className="ofirmie__kontener-tresc padding__wysokosc-sekcji padding__szerokosc-sekcji padding__zerowanie-prawej-strony">
          <div className="ofirmie__kontener-tresc-tytulSekcji tytul__sekcji">
            <h4>Zatrzymaj się na chwilę, opowiemy Ci</h4>
            <h2>Naszą Historię</h2>
          </div>
          <div className="ofirmie__paragraph-section">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque
              exercitationem similique blanditiis excepturi dicta officia, optio
              iste ea nisi repellat, tempore odit soluta nobis quod magnam
              corporis quaerat, aperiam nam!
            </p>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque
              exercitationem similique blanditiis excepturi dicta officia, optio
              iste ea nisi repellat, tempore odit soluta nobis quod magnam
              corporis quaerat, aperiam nam!
            </p>
          </div>
        </div>
        <div className="ofirmie__kontener-img">
          <img src={About01} alt="o_nas" />
        </div>
      </div>
    </div>
  );
};

export default Ofirmie;
