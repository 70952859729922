import React from "react";
import "./Stopka.css";
import { AllRights, Menu } from "../../components";

const Stopka = () => {
  return (
    <div className="stopka__kontener">
      <div className="stopka__kontener-zawartosc  padding__szerokosc-sekcji padding__wysokosc-sekcji">
        <div className="stopka__logo ">
          <div className="stopka__tytul-sekcji">
            <h4>twoja-altanka.pl</h4>
          </div>
          <div className="stopka__logo-opis">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Excepturi distinctio perspiciatis quam fuga eius itaque autem
              sapiente laborum officiis! Voluptatibus ex quae minima quo
              quibusdam voluptate? Eveniet mollitia excepturi voluptatum?
            </p>
          </div>
          <div className="stopka__span-dol">
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="stopka__nawigacja">
          <div className="stopka__tytul-sekcji">
            <h4>Nawigacja</h4>
          </div>
          <div className="stopka__menu">
            <Menu />
            <Menu />
          </div>
          <div className="stopka__span-dol">
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="stopka__kontakt">
          <div className="stopka__tytul-sekcji">
            <h4>Kontakt</h4>
          </div>
          <div className="kontakt__zawartosc">
            <p>ul. Nazwa Ulicy 42</p>
            <p>00 - 000 Miejscowość</p>
            <p>kontakt@twoja-altanka.pl</p>
            <p>+48 000 - 000 - 000</p>
          </div>
          <div className="stopka__span-dol">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <AllRights />
    </div>
  );
};

export default Stopka;
