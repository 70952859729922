import React from "react";
import "./OfertaDrewutnie.css";
import { ProductCard } from "../../../../components";
import { drewutnieData } from "../../../../libs";

const OfertaDrewutnie = () => {
  return (
    <div className="oferta__drewutnie productCard-ustawienie">
      {drewutnieData.map((data, index) => {
        return (
          <ProductCard
            title={data.title}
            kategoria={data.kategoria}
            id={data.id}
            img={data.img}
            text={data.text}
            cena={data.cena}
            wysokosc={data.wysokosc}
            szerokosc={data.szerokosc}
            key={index + data.title}
          />
        );
      })}
    </div>
  );
};

export default OfertaDrewutnie;
