import React, { useState } from "react";
import "./Nawigacja.css";
import { Menu } from "../../components";
import { AiOutlineClose, AiOutlinePhone } from "react-icons/ai";

const Nawigacja = () => {
  const [mobileMenu, setmobileMenu] = useState(false);

  return (
    <div className="nawigacja__kontener">
      <div className="nawigacja__dekstop padding__szerokosc-sekcji">
        <div className="nawigacja-logo__kontener">
          <p>twoja-altanka.pl</p>
        </div>
        <div className="nawigacja-menu__kontener-desktop">
          <Menu />
        </div>
      </div>
      <div className="nawigacja__mobile ">
        <div className="nawigacja__mobile-kontener padding__szerokosc-sekcji">
          <div className="nawigacja-logo__kontener">
            <p>twoja-altanka.pl</p>
          </div>
          <div className="nawigacja-kontakt__kontener">
            <span>
              <AiOutlinePhone />
            </span>
            <span>+48 000 - 000 - 000</span>
          </div>
          {!mobileMenu ? (
            <div
              className="nawigacja-hamburgerIcon"
              role="button"
              onClick={() => setmobileMenu(true)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            <div
              className="nawigacja-iconX"
              role="button"
              onClick={() => setmobileMenu(false)}>
              <span>
                <AiOutlineClose />
              </span>
            </div>
          )}
        </div>
        {mobileMenu && (
          <div className="nawigacja__moebile-kontenerMenu padding__szerokosc-sekcji">
            <div className="nawigacja-kontakt__kontener">
              <span>
                <AiOutlinePhone />
              </span>
              <span>+48 000 - 000 - 000</span>
            </div>
            <Menu />
          </div>
        )}
      </div>
    </div>
  );
};

export default Nawigacja;
