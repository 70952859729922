import React from "react";

const AllRights = () => {
  return (
    <div className="stopka__allrights-kontener">
      <p>twojamarkawsieci.pl - Demo Altanki 2023</p>
    </div>
  );
};

export default AllRights;
