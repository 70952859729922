import { Offer01, Offer02, Offer03 } from "../assets";

export const altankiData = [
  {
    title: "Dorota",
    kategoria: "altanki",
    id: "altanka_01",
    img: Offer01,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "3500 PLN",
    wysokosc: "350 cm",
    szerokosc: "550 cm"
  },
  {
    title: "Laura",
    kategoria: "altanki",
    id: "altanka_02",
    img: Offer02,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4500 PLN",
    wysokosc: "550 cm",
    szerokosc: "750 cm"
  },
  {
    title: "Nadia",
    kategoria: "altanki",
    id: "altanka_03",
    img: Offer03,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4000 PLN",
    wysokosc: "350 cm",
    szerokosc: "750 cm"
  }
];

export const domkiData = [
  {
    title: "Dorota",
    kategoria: "domki",
    id: "domek_01",
    img: Offer01,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "3500 PLN",
    wysokosc: "350 cm",
    szerokosc: "550 cm"
  },
  {
    title: "Laura",
    kategoria: "domki",
    id: "domek_02",
    img: Offer02,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4500 PLN",
    wysokosc: "550 cm",
    szerokosc: "750 cm"
  },
  {
    title: "Nadia",
    kategoria: "domki",
    id: "domek_03",
    img: Offer03,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4000 PLN",
    wysokosc: "350 cm",
    szerokosc: "750 cm"
  }
];

export const drewutnieData = [
  {
    title: "Dorota",
    kategoria: "drewutnie",
    id: "drewutnia_01",
    img: Offer01,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "3500 PLN",
    wysokosc: "350 cm",
    szerokosc: "550 cm"
  },
  {
    title: "Laura",
    kategoria: "drewutnie",
    id: "drewutnia_02",
    img: Offer02,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4500 PLN",
    wysokosc: "550 cm",
    szerokosc: "750 cm"
  },
  {
    title: "Nadia",
    kategoria: "drewutnie",
    id: "drewutnia_03",
    img: Offer03,
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, repellendus libero iusto assumenda laborum amet, ea unde ut dolor corrupti omnis aliquam veritatis nisi. Deserunt vel velit placeat doloremque ea!",
    cena: "4000 PLN",
    wysokosc: "350 cm",
    szerokosc: "750 cm"
  }
];
