import React from "react";
import "./ProductCard.css";
import { Offer03 } from "../../assets";
import { BsEye } from "react-icons/bs";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { SlSizeFullscreen } from "react-icons/sl";
import { NavLink } from "react-router-dom";

const ProductCard = ({
  title,
  id,
  img,
  text,
  cena,
  wysokosc,
  szerokosc,
  kategoria
}) => {
  return (
    <div
      className="productCard__wnetrze"
      style={{ backgroundImage: `url(${img})` }}>
      <div className="overlay"></div>
      <div className="productCard__title__box">
        <div className="productCard__title__box-triangle"></div>
        <div className="productCard__title__box-title">
          <div className="productCard__title__box-text-section">
            <h5>
              {(kategoria === "altanki" && "Altanka") ||
                (kategoria === "domki" && "Domek") ||
                (kategoria === "drewutnie" && "Dretutnia")}
            </h5>
            <h3>{title}</h3>
          </div>
        </div>
      </div>
      <div className="productCard__showMoreButton">
        <button>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div className="productCard__showMore-card-kontener">
        <div className="productCard__showMore-card">
          <div className="productCard__showMore-card_title">
            <h5>
              {(kategoria === "altanki" && "Altanka") ||
                (kategoria === "domki" && "Domek") ||
                (kategoria === "drewutnie" && "Dretutnia")}
            </h5>
            <h3>{title}</h3>
          </div>
          <div className="productCard__wymiary">
            <h4>
              <span>
                <SlSizeFullscreen />
              </span>{" "}
              Wymiary:
            </h4>
            <p>
              <span>
                <AiOutlineColumnWidth />
              </span>{" "}
              {wysokosc}
            </p>
            <p>
              <span>
                <AiOutlineColumnWidth />
              </span>{" "}
              {szerokosc}
            </p>
          </div>
          <div className="productCard__showMore-card_nawigacja">
            <button>{cena}</button>

            <NavLink
              className={"product_link_button"}
              to={`/produkty/${kategoria}/${id}`}>
              zobacz więcej...{" "}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
