import React from "react";
import {
  Header,
  Nawigacja,
  Oferta,
  Realizacje,
  Ofirmie,
  Kontakt,
  Stopka
} from "../../containers";

const Home = () => {
  return (
    <div className="home__kontener">
      <div className="naiwgacja__mobilna-kotener-app">
        <Nawigacja />
      </div>
      <Header />
      <Oferta />
      {/* <Realizacje /> */}
      <Ofirmie />
      <Kontakt />
      <Stopka />
    </div>
  );
};

export default Home;
