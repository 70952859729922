import React from "react";
import "./OfertaKwadrat.css";

const OfertaKwadrat = ({ img, title, text }) => {
  return (
    <div className="ofertakwadrat__kontener">
      <div className="ofertakwadrat-ikona">
        <img src={img} alt={title} />
      </div>
      <div className="ofertakwadrat-tytuł">
        <h2>{title}</h2>
      </div>
      <div className="ofertakwadrat-opis">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default OfertaKwadrat;
